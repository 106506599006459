import { ITrackingPayload } from '../types/analytics';

declare global {
    interface Window {
        _satellite: any;
    }
}

declare global {
    interface Window {
        adobeDataLayer: any;
        digitalData: any;
    }
}

export const ANALYTICS_CONSTANTS = {
    EVENTS: {
        CLICK: {
            CTA: 'ctaClick',
            SAVE_RECOMMENDED_CAR: 'recommendedSaveCarClick',
            SEARCH_INITIATED: 'searchInitiated',
        },
        PAGE: {
            LOAD: 'pageLoad',
            STATE: 'pageState',
        },
    },
    PAGE_STATE_LOAD_TIME: 2000,
};

export const trackEDDL = (payload: ITrackingPayload): void => {
    if (payload.event == null) {
        payload.event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
    }
    // "adobeDataLayer" will appear in the console if the site in questions is using "EDDL"
    // Note: adobeDataLayer is created by Adobe, not us. Thus, we don't create it if it doesn't exist.
    if (window.adobeDataLayer) {
        window.adobeDataLayer.push(payload);
    } else {
        console.log('Home Microsite : adobeDataLayer is undefined. Data did not track.');
    }
};
