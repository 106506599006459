import { getVehicles } from '../api/search-api';
import { getVehiclesFromWebVehicle } from '../api/vehicle-service-api';
import { getVehiclesFromRVC } from '../api/rvc-api';
import { getVehicleRecommendations } from '../api/vehicle-recommendations-api';
import { Vehicle } from '../api/types';
import { IVehicleTile } from '../types/vehicleTile';
import {
    MAX_RECENTLY_VIEWED_VEHICLE_TILES,
    MAX_BEST_MATCH_VEHICLE_TILES,
    BEST_MATCH_VERSION,
    statusCodesForUnavailableVehicles,
    viewedVehiclesLocalStorage,
} from '../pages/home/utilities/constants';
import {
    filterOutExpiredVehicles,
    getExpirationDate,
    getLocalStorageItem,
    translateVehicleStatus,
} from '../pages/home/utilities/helpers';
import { IViewedVehicleLocalStorage } from '../pages/home/utilities/types';

export const getRecentlyViewedVehicleTiles = (
    maxTiles = MAX_RECENTLY_VIEWED_VEHICLE_TILES,
    nearestStoreId?: string
): Promise<IVehicleTile[]> => {
    // Get viewed vehicles from localStorage
    const viewedVehicles: IViewedVehicleLocalStorage[] = getLocalStorageItem(viewedVehiclesLocalStorage.key) || [];

    // Keep only vehicles seen in the past {DAYS_BEFORE_HOMEBASE_VISITOR_EXPIRES}
    const expirationDate = getExpirationDate();
    const filteredVehicles = filterOutExpiredVehicles(viewedVehicles, expirationDate);

    // Call API to get data related to the viewed vehicles
    if (filteredVehicles.length > 0) {
        const sortedViewedVehicles = getSortedViewedVehicleStockNumbersByDate(filteredVehicles);
        return getVehicles({
            stockNumberList: sortedViewedVehicles,
            take: maxTiles,
            transferDestinationStoreId: nearestStoreId,
        }).then(function (vehiclesList: Vehicle[]) {
            if (vehiclesList.length > 0) {
                // Sometimes, the API might not return some vehicles, and we need to call a different API endpoint (home/api/vehicles) to get vehicle details.
                // Reason: The vehicle's status may have changed; for example, it is now reserved.
                const { isMissing, missingItems } = isResponseMissingVehicles(vehiclesList, sortedViewedVehicles);
                if (isMissing) {
                    return getVehiclesFromRVC({
                        stockNumberList: missingItems,
                    }).then((items) => {
                        const combinedItems = [...vehiclesList, ...items];
                        console.log(combinedItems);
                        return buildVehicleTilesListFromVehicleList(
                            buildOrderedVehicleTileList(sortedViewedVehicles, combinedItems)
                        );
                    });
                } else {
                    return buildVehicleTilesListFromVehicleList(
                        buildOrderedVehicleTileList(sortedViewedVehicles, vehiclesList)
                    );
                }
            } else {
                return Promise.resolve([]);
            }
        });
    } else {
        return Promise.resolve([]);
    }
};

export const getRecommendedVehicleTiles = (
    sourceStockNumbers: string[],
    lat: number,
    long: number,
    visitorId: string
): Promise<IVehicleTile[]> => {
    if (sourceStockNumbers && sourceStockNumbers.length > 0) {
        const sortedSourceStockNumbers = sourceStockNumbers.sort();
        return getVehicleRecommendations(sortedSourceStockNumbers, lat, long, visitorId).then(function (
            vehiclesList: Vehicle[]
        ) {
            if (vehiclesList.length > 0) {
                return buildVehicleTilesListFromVehicleList(vehiclesList);
            } else {
                return Promise.resolve([]);
            }
        } as any);
    } else {
        return Promise.resolve([]);
    }
};

export const getBestMatchVehicleTiles = (
    maxTiles = MAX_BEST_MATCH_VEHICLE_TILES,
    nearestStoreId?: string
): Promise<IVehicleTile[]> => {
    return getVehicles({
        take: maxTiles,
        scoringProfile: BEST_MATCH_VERSION,
        transferDestinationStoreId: nearestStoreId,
    }).then(function (vehiclesList: Vehicle[]) {
        if (vehiclesList.length > 0) {
            return buildVehicleTilesListFromVehicleList(vehiclesList);
        } else {
            return Promise.resolve([]);
        }
    } as any);
};

export const getLocalVehiclesData = async (params: {
    take: number;
    zipCode: string;
    radiusInMiles: number;
    storeId: string;
}): Promise<IVehicleTile[]> => {
    const vehiclesList = await getVehicles({
        take: params.take,
        zipCode: params.zipCode,
        radiusInMiles: params.radiusInMiles,
        storeId: params.storeId,
    });
    if (vehiclesList.length > 0) {
        return buildVehicleTilesListFromVehicleList(vehiclesList);
    } else {
        return Promise.resolve([]);
    }
};

export const buildSavedVehicleTiles = (
    savedVehicleStockNumberList: number[],
    cb: (vehicleTileList: IVehicleTile[]) => any,
    nearestStoreId?: string
) => {
    if (savedVehicleStockNumberList && savedVehicleStockNumberList.length > 0) {
        return getVehicles({
            stockNumberList: savedVehicleStockNumberList,
            transferDestinationStoreId: nearestStoreId,
        }).then((vehiclesList: Vehicle[]) => {
            if (vehiclesList.length > 0) {
                const orderedVehicleList = buildOrderedVehicleTileList(savedVehicleStockNumberList, vehiclesList);
                const vehicleTileList = buildVehicleTilesListFromVehicleList(orderedVehicleList);
                cb(vehicleTileList);
            } else {
                cb([]);
            }
        });
    } else {
        cb([]);
    }
};

const buildOrderedVehicleTileList = (orderedStockNumberList: number[], vehiclesList: Vehicle[]) => {
    const orderedVehicleList: Vehicle[] = [];

    for (let i = 0; i < vehiclesList.length; i++) {
        const orderedIndex = orderedStockNumberList.indexOf(vehiclesList[i].stockNumber);
        orderedVehicleList[orderedIndex] = vehiclesList[i];
    }

    return orderedVehicleList;
};

export const buildVehicleTilesListFromVehicleList = (vehicleList: Vehicle[]): IVehicleTile[] => {
    return vehicleList.map(
        (vehicle: Vehicle): IVehicleTile => {
            const imageLink = `https://img2.carmax.com/assets/${vehicle.stockNumber}/hero.jpg?width=270&height=203`;
            vehicle.imageLink = imageLink;
            return vehicle;
        }
    );
};

export const isResponseMissingVehicles = (
    vehiclesReturned: Vehicle[],
    vehiclesRequested: number[]
): { isMissing: boolean; missingItems: number[] } => {
    if (vehiclesReturned.length !== vehiclesRequested.length) {
        const foundItems = vehiclesReturned.map((item) => item.stockNumber);

        const notFound = vehiclesRequested?.filter((stockNumber) => {
            const isIncluded = foundItems.includes(stockNumber);
            if (!isIncluded) {
                return stockNumber;
            }
            return;
        });

        return {
            isMissing: notFound.length > 0,
            missingItems: notFound,
        };
    }
    return {
        isMissing: false,
        missingItems: [],
    };
};

export const buildStatusText = (vehicleTile: IVehicleTile): string => {
    return translateVehicleStatus(vehicleTile);
};

export const vehicleIsUnavailable = (statusCode: number, isSaleable: boolean): boolean => {
    return !isSaleable && statusCodesForUnavailableVehicles.indexOf(statusCode) > -1;
};

const getSortedViewedVehicleStockNumbersByDate = (vehicles: IViewedVehicleLocalStorage[]): number[] => {
    return vehicles
        .sort(({ timestamp: a }: IViewedVehicleLocalStorage, { timestamp: b }: IViewedVehicleLocalStorage) => b - a)
        .map(({ stockNumber }: IViewedVehicleLocalStorage) => Number(stockNumber));
};
