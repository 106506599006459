import { memo, FC, useEffect } from 'react';
import Carousel from './Carousel';
import { Icons } from './Icons';
import { CardData, cardsData } from './cards-data';
import { ANALYTICS_CONSTANTS, trackEDDL } from '../../../utilities/analytics';
import { ITrackingPayload } from '../../../types/analytics';
import { Typography } from '@mui/material';

interface IColdStartCarousel {
    bucketName?: string;
}

const handleOnClick = (href: string, payload: ITrackingPayload) => {
    // Analytics
    trackEDDL(payload);

    // Redirect
    setTimeout(() => {
        window.location.href = href;
    }, 250);
};

const Card = memo(({ backgroundId, label, href }: CardData) => {
    const payload = {
        event: ANALYTICS_CONSTANTS.EVENTS.CLICK.SEARCH_INITIATED,
        linkDetails: {
            name: label,
            position: 'cold start carousel',
        },
        search: {
            initiationMethod: `Homepage|${label}|cold start carousel`,
        },
    };
    return (
        <div
            className="cold-start__card"
            card-background-id={backgroundId}
            aria-label={label}
            role="button"
            tabIndex={0}
            onClick={() => handleOnClick(href, payload)}
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    handleOnClick(href, payload);
                    event.preventDefault();
                }
            }}
        >
            <div className="cold-start__label">
                <div>{label}</div>
                <Icons.Arrow />
            </div>
        </div>
    );
});

const ColdStartCarousel: FC<IColdStartCarousel> = () => {
    const label = 'Explore cars that fit your life';
    const payload = {
        event: ANALYTICS_CONSTANTS.EVENTS.CLICK.SEARCH_INITIATED,
        linkDetails: {
            name: label,
            position: 'cold start carousel',
        },
        search: {
            initiationMethod: `Homepage|${label}|cold start carousel`,
        },
    };

    useEffect(() => {
        setTimeout(function () {
            const payload: ITrackingPayload = {
                event: ANALYTICS_CONSTANTS.EVENTS.PAGE.STATE,
                pageState: {
                    pageState: `Cold-Start:Variant`,
                },
            };
            trackEDDL(payload);
        }, ANALYTICS_CONSTANTS.PAGE_STATE_LOAD_TIME);
    }, []);

    return (
        <div id="cold-start-carousel-container" className="carousel--container">
            <div className="carousel--grid">
                <div className="carousel--title">
                    <Typography variant="h1" gutterBottom>
                        Explore cars <br className="carousel--title-br" /> that fit your life
                    </Typography>
                    <button type="button" title={label} onClick={() => handleOnClick('/cars/all', payload)}>
                        <Icons.Search />
                    </button>
                </div>
                <Carousel nextFocus="budget-calculator-section" name="cold-start" leftAligned={true}>
                    {cardsData.map(({ backgroundId, label, href }: CardData, i: number) => (
                        <Card key={i} backgroundId={backgroundId} label={label} href={href} />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

Card.displayName = 'Card';

export default ColdStartCarousel;
